/* eslint-disable global-require */
import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { SpinnerDotted } from 'spinners-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { confirmAlert } from 'react-confirm-alert';
import { toast, ToastContainer } from 'react-toastify';
import { getCookies } from '../../hooks/useCookies';
import { TableContext } from '../../contexts/tableContext';
import 'react-toastify/dist/ReactToastify.css';
import styles from './viewClaim.module.scss';
import envValues from '../../enviornment';

function ViewClaim() {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { viewformStyle } = useContext(TableContext);
  const navigate = useNavigate();
  const params = useParams();
  let parentId;
  const token = `Bearer ${getCookies('Token')}`;
  const [loading, setLoading] = useState(true);
  const [submiting, setSubmitting] = useState(false);
  const [submitingReject, setSubmittingReject] = useState(false);
  const [details, setDetails] = useState([]);
  // const [roles, setRole] = useState([]);
  let statusCode;

  const approveClaimRequest = async () => {
    confirmAlert({
      title: '',
      message: 'Are you sure you want to give this user access to the Team?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setSubmitting(true);
            axios
              .post(
                `${envValues.REACT_APP_API_FRONT_END}/app/team/approve-claim-request/${params.parentId}`,
                {
                  method: 'POST',
                  headers: { Authorization: token },
                }
              )
              .then((resp) => resp.data)
              .then((res) => {
                if (res.success === true) {
                  setSubmitting(false);
                  // setRole(res.data.user.role);
                  setLoading(false);
                  setSubmitting(false);
                  toast.success('Claim request processed successfully');
                  navigate('/claims');
                } else {
                  setLoading(false);
                  setSubmitting(false);
                  toast.error(res.data.message);
                }
              })
              .catch((err) => {
                setLoading(false);
                setSubmitting(false);
                return err;
              });
          },
        },
        {
          label: 'No',
          // onClick: () => alert("Click No")
        },
      ],
    });
  };

  const rejectClaimRequest = async () => {
    confirmAlert({
      title: '',
      message: 'Are you sure you want to reject the Claim request?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setSubmittingReject(true);
            axios
              .post(
                `${envValues.REACT_APP_API_FRONT_END}/app/team/delete-claim-request/${params.parentId}`,
                {
                  method: 'POST',
                  headers: { Authorization: token },
                }
              )
              .then((resp) => resp.data)
              .then((res) => {
                if (res.success === true) {
                  setSubmittingReject(false);
                  // setRole(res.data.user.role);
                  setLoading(false);
                  setSubmittingReject(false);
                  toast.success('Claim request rejected successfully');
                  navigate('/claims');
                } else {
                  setLoading(false);
                  setSubmittingReject(false);
                  toast.error(res.data.message);
                }
              })
              .catch((err) => {
                setLoading(false);
                setSubmittingReject(false);
                return err;
              });
          },
        },
        {
          label: 'No',
          // onClick: () => alert("Click No")
        },
      ],
    });
  };

  const getUserDetails = async () => {
    setLoading(true);
    setSubmitting(false);
    axios
      .get(`${envValues.REACT_APP_API_FRONT_END}/app/team/get-claim/${parentId}`, {
        method: 'GET',
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          setDetails(res.data.claim);
          console.log(res.data.claim);
          // setRole(res.data.user.role);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    parentId = params.parentId;
    getUserDetails();
    setDashboardHeader('Claim Details');
  }, []);

  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ height: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          {loading ? (
            <SpinnerDotted
              style={{
                color: '#39979d',
                left: '50%',
                position: 'relative',
                textAlign: 'center',
                top: '50%',
              }}
            />
          ) : (
            <Container fluid>
              <FontAwesomeIcon
                icon={faArrowLeft}
                value="Back"
                onClick={() => {
                  navigate(-1);
                }}
                className={styles.arrowback}
              />
              <Row className="mt-3">
                <Col lg={12} md={12} sm={12}>
                  <div className={styles.detaildiv}>
                    <Row>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Team Name</span>
                          <p className={styles.empname}>{details?.teamId?.teamName}</p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Requested By</span>
                          <p className={styles.empname}>{details?.fullName}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Email</span>
                          <p className={styles.empname}>{details?.email}</p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Address</span>
                          <p className={styles.empname}>{details?.address}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Phone Number</span>
                          <p className={styles.empname}>{details?.phone}</p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>About</span>
                          <p className={styles.empname}>{details?.aboutUs}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Website</span>
                          <p className={styles.empname}>{details?.website}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <div className="d-flex">
                        <input
                          className={styles.resetbtn}
                          type="button"
                          disabled={submitingReject}
                          value={submitingReject ? 'Please wait...' : 'Reject Request'}
                          onClick={rejectClaimRequest}
                        />{' '}
                        &nbsp;
                        <input
                          className={styles.formbtn}
                          type="button"
                          disabled={submiting}
                          value={submiting ? 'Please wait...' : 'Approve Request'}
                          onClick={approveClaimRequest}
                        />
                      </div>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </div>
        <ToastContainer autoClose={2000} />
      </div>
    </div>
  );
}

export default ViewClaim;
