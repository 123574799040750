/* eslint-disable react/function-component-definition */
import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { SpinnerDotted } from 'spinners-react';
import { getCookies } from '../../hooks/useCookies';
import { TableContext } from '../../contexts/tableContext';
import { REACT_APP_API_FETCH_ADMIN_LOGIN_TOKEN, WEBSITE_URL } from '../../redux/apiConstants';
import styles from './create.module.scss';

const TeamCreate = React.memo(() => {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const navigate = useNavigate();
  const getAutologinToken = async (adminId, token) => {
    const rawResponse = await fetch(`${REACT_APP_API_FETCH_ADMIN_LOGIN_TOKEN}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ adminId }),
    });
    const userData = await rawResponse.json();
    if (userData.success) {
      const { autoLoginToken } = userData.data;
      // console.log(autoLoginToken, 'autoLoginToken');
      window.location.href = `${WEBSITE_URL}/signin?autoLoginToken=${autoLoginToken}&adminId=${adminId}&mode=team-create`;
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Add Team Details');
    const adminId = getCookies('USERID');
    const token = getCookies('Token');
    getAutologinToken(adminId, token);
  }, []);
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ height: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />

          <div
            style={{
              textAlign: 'center',
              marginTop: '160px',
              marginBottom: '160px',
              color: '#7a68c2',
            }}
          >
            <SpinnerDotted style={{ color: '#39979d' }} />
          </div>
        </div>
      </div>
    </div>
  );
});

export default TeamCreate;
