import envValues from '../enviornment';

export const SERVER_URL = `${envValues.REACT_APP_API_ENDPOINT}/auth`;
export const WEBSITE_URL = `${envValues.REACT_APP_FRONTEND}`;
export const SERVER_BASE_URL = envValues.REACT_APP_API_ENDPOINT;
export const ERROR_MESSAGE = 'Api Fetch Error!';
export const ENCRYPT_SECERET_KEY = 'armia-secret-key@123';
export const REACT_APP_API_POST_USER = `${envValues.REACT_APP_API_ENDPOINT}/user`;
export const REACT_APP_API_FETCH_USER = `${envValues.REACT_APP_API_ENDPOINT}/user?`;
export const REACT_APP_API_FETCH_EMPLOYEE = `${envValues.REACT_APP_API_ENDPOINT}/employee?active=true&`;
export const REACT_APP_API_FETCH_ROLE = `${envValues.REACT_APP_API_ENDPOINT}/roles?active=true&`;
export const REACT_APP_API_SKILLS = `${envValues.REACT_APP_API_ENDPOINT}/dummy/selectdata`;
export const REACT_APP_API_ROLES_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/roles/select`;
export const REACT_APP_API_FETCH_MOVIES = `${envValues.REACT_APP_API_ENDPOINT}/movies?active=true&`;
export const REACT_APP_API_FETCH_NOTIFICATIONS = `${envValues.REACT_APP_API_ENDPOINT}/notifications?`;
export const REACT_APP_API_FETCH_PARENT = `${envValues.REACT_APP_API_FRONT_END}/app/user/list?role=parent&`;
export const REACT_APP_API_FETCH_PLAYERS = `${envValues.REACT_APP_API_FRONT_END}/app/player/list?`;
export const REACT_APP_API_FETCH_REVIEWS = `${envValues.REACT_APP_API_FRONT_END}/app/reviews/list?`;
export const REACT_APP_API_FETCH_TEAM = `${envValues.REACT_APP_API_FRONT_END}/app/team/list?`;
export const REACT_APP_API_FETCH_CLAIM_REQUEST = `${envValues.REACT_APP_API_FRONT_END}/app/team/claim-request?`;
export const REACT_APP_API_FETCH_ADMIN_LOGIN_TOKEN = `${envValues.REACT_APP_API_FRONT_END}/app/auth/generate-adminlogin-token`;
export const REACT_APP_API_FETCH_PRODUCT = `${envValues.REACT_APP_API_ENDPOINT}/stripe/product?`;
export const REACT_APP_API_PRODUCTS_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/stripe/product/list`;
export const REACT_APP_API_FETCH_PRICE = `${envValues.REACT_APP_API_ENDPOINT}/stripe/price?`;
export const REACT_APP_API_FETCH_SUBSCRIPTIONS = `${envValues.REACT_APP_API_ENDPOINT}/app/user/subscription-list?`;
export const REACT_APP_API_FETCH_TRANSACTIONS = `${envValues.REACT_APP_API_ENDPOINT}/app/user/transactions-list?`;
export const REACT_APP_API_FETCH_ENQUIRIES = `${envValues.REACT_APP_API_FRONT_END}/app/enquiry/list?sortBy=createdAt:-1&`;
export const REACT_APP_API_FETCH_REPORTEDTOPICS = `${envValues.REACT_APP_API_FRONT_END}/app/topics/reported-topics?`;
export const REACT_APP_API_FETCH_REPORTEDCOMMENTS = `${envValues.REACT_APP_API_FRONT_END}/app/comments/reported-comments?`;
