/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { SpinnerDotted } from 'spinners-react';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { Entry } from '../../redux/entrySlice';
import EmailInput from '../../components/EmailBox/EmailBox';
import TextInput from '../../components/TextField/TextField';
import SelectBox from '../../components/SelectBox/SelectBox';
import PhoneNumberInput from '../../components/PhoneNumberBox/PhoneNumberBox';
import CheckBoxField from '../../components/CheckBox/CheckBox';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import { TableContext } from '../../contexts/tableContext';
import styles from './editPrice.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import { Decrypt } from '../../utilityFunctions/utilsFunctions';
import { REACT_APP_API_PRODUCTS_DROPDOWN } from '../../redux/apiConstants';

const PriceEdit = React.memo(() => {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const [submiting, setSubmitting] = useState(false);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState([]);
  let priceId;
  const [defaultDesignation, setDefaultDesignation] = useState();
  const [defaultStatus, setDefaultStatus] = useState();
  const params = useParams();
  const [error, setError] = useState(null);
  const [price, setPrice] = useState([]);
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const intervals = [
    {
      label: 'Monthly',
      value: 'month',
    },
    {
      label: 'Yearly',
      value: 'year',
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Edit Price Details');
    setLoading(true);
    priceId = params.priceId;
    const url = REACT_APP_API_PRODUCTS_DROPDOWN;
    fetch(url)
      .then((resp) => resp.json())
      .then((resp) => setProducts(resp.data.product.selectdata));

    const actionData = {};
    actionData.actionUrl = `stripe/price/${priceId}`;
    actionData.actionMethod = 'get';
    dispatch(Entry(actionData)).then((resp) => {
      setLoading(false);
      delete resp.payload.id;
      setProduct(resp.payload.data.price.productId);
      // setDefaultDesignation(resp.payload.data.user.designation);
      // setDefaultStatus(resp.payload.status);
      setPrice(resp.payload.data.price);
      reset(resp.payload.data.price);
    });
  }, []);
  function onSubmitdata(data) {
    confirmAlert({
      title: '',
      message:
        'Editing Price will create new Price in Strip,Existing Price will be disabled.Do you want procceed',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setSubmitting(true);
            const apiData = {
              productId: data.productId instanceof Object ? data.productId.value : data.productId,
              amount: data.amount,
              interval: data.interval instanceof Object ? data.interval.value : data.interval,
            };
            data.actionUrl = `stripe/price/update-price/${params.priceId}`;
            data.actionMethod = 'post';
            data.apiData = apiData;
            dispatch(Entry(data)).then((resp) => {
              setSubmitting(false);
              if (resp.payload.code === 200) {
                navigate('/price');
                toast.success('Price updation successfull');
              } else if (resp.payload.code === 401 || resp.payload.code === 400) {
                setError(resp.payload.message);
                toast.error('Error');
              } else {
                navigate(-1);
              }
            });
          },
        },
        {
          label: 'No',
          // onClick: () => alert("Click No")
        },
      ],
    });
  }

  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ height: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          {loading ? (
            <div
              style={{
                textAlign: 'center',
                marginTop: '160px',
                marginBottom: '160px',
                color: '#7a68c2',
              }}
            >
              <SpinnerDotted style={{ color: '#39979d' }} />
            </div>
          ) : (
            <div className="container-fluid mt-5">
              <form onSubmit={handleSubmit(onSubmitdata)}>
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    {price && (
                      <div>
                        <SelectBox
                          className={styles.inputbox}
                          classNamedark={styles.inputbox1}
                          name="productId"
                          label="Product Name"
                          labelstyle={styles.formlabel}
                          control={control}
                          register={register}
                          values={products}
                          errors={errors}
                          placeholder="Choose Product"
                          mandatory
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <TextInput
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      label="Amount"
                      fieldName="amount"
                      placeHolder="Enter Price Amount"
                      register={register}
                      errors={errors}
                      isRequired
                      mandatory
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <SelectBox
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      name="interval"
                      label="Price Interval"
                      labelstyle={styles.formlabel}
                      control={control}
                      register={register}
                      values={intervals}
                      errors={errors}
                      placeholder="Choose Interval"
                      mandatory
                    />
                  </div>
                </div>
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? 'Please wait..' : 'Update'}
                />
              </form>
              <br />
              <ToastContainer autoClose={2000} />
            </div>
          )}
          {error && <h6 className={styles[errormsgStyle]}>{error}</h6>}
        </div>
      </div>
    </div>
  );
});

export default PriceEdit;
