import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { getCookies } from '../../hooks/useCookies';
import styles from './ToggleButton.module.scss';
import envValues from '../../enviornment';

function ToggleButtonOnOff({ value, id, section }) {
  const [isOff, setIsOff] = useState(false);

  useEffect(() => {
    setIsOff(value);
  }, [value]);

  const handleButtonClick = () => {
    confirmAlert({
      title: 'Confirm to change status',
      message: 'Are you sure want to change status?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setIsOff(!isOff);
            const headers = {
              'Content-Type': 'application/json; charset=utf-8',
              'Access-Control-Allow-Origin': '*',
            };
            // console.log(section, 'section');
            if (section === 'Price') {
              const body = { token: getCookies('Token'), active: !isOff };
              axios
                .post(`${envValues.REACT_APP_API_ENDPOINT}/stripe/price/${id}`, body, headers)
                .then(() => {});
            } else if (section === 'Review') {
              const body = { token: getCookies('Token'), active: !isOff, reviewId: id };
              axios
                .post(
                  `${envValues.REACT_APP_API_ENDPOINT}/app/reviews/update-review/${id}`,
                  body,
                  headers
                )
                .then(() => {});
            } else if (section === 'Team') {
              const body = { token: getCookies('Token'), active: !isOff, teamId: id };
              axios
                .post(
                  `${envValues.REACT_APP_API_ENDPOINT}/app/team/update-team-status/${id}`,
                  body,
                  headers
                )
                .then(() => {});
            } else if (section === 'reported-comments') {
              const body = { token: getCookies('Token'), active: !isOff, teamId: id };
              axios
                .post(
                  `${envValues.REACT_APP_API_ENDPOINT}/app/comments/disable-comment/${id}`,
                  body,
                  headers
                )
                .then(() => {});
            } else if (section === 'reported-topics') {
              const body = { token: getCookies('Token'), active: !isOff, teamId: id };
              axios
                .post(
                  `${envValues.REACT_APP_API_ENDPOINT}/app/topics/disable-topic/${id}`,
                  body,
                  headers
                )
                .then(() => {});
            } else if (section === 'enquiries') {
              const body = { token: getCookies('Token'), active: !isOff, teamId: id };
              axios
                .post(
                  `${envValues.REACT_APP_API_ENDPOINT}/app/enquiry/toggle-contact-status/${id}`,
                  body,
                  headers
                )
                .then(() => {});
            } else {
              const body = { token: getCookies('Token'), userid: id, active: !isOff };
              axios
                .post(`${envValues.REACT_APP_API_FRONT_END}/app/user/toggle-status`, body, headers)
                .then(() => {});
            }
          },
        },
        {
          label: 'No',
          // onClick: () => alert("Click No")
        },
      ],
    });
  };
  return (
    <div className="col ">
      <label className={styles.switch}>
        <input type="checkbox" checked={isOff} onClick={handleButtonClick} />
        <span className={styles.slider} />
      </label>
    </div>
  );
}

ToggleButtonOnOff.propTypes = {
  value: PropTypes.bool,
  id: PropTypes.string,
  section: PropTypes.string,
};
ToggleButtonOnOff.defaultProps = {
  value: false,
  id: null,
  section: null,
};
export default ToggleButtonOnOff;
